import "../styles/pages/page-news.scss";

import React from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import theme from "theme";

import Layout from "components/Layout";
import Seo from "components/Seo";
import SubpageHeader from "components/SubpageHeader";
import NewsTile from "components/NewsTile";
import Newsletter from "components/Newsletter";

const Button = styled.div`
  display: inline-block;
  text-align: center;
  padding: 10px 0;
  margin: 0 15px 3% 0;
  width: 140px;
  border-radius: 4px;
  transition: all 0.25s ease-in-out;

  // Active checks
  color: ${({ active }) => (active ? `${theme.colors.pink}` : "#d5ddf0")};
  border: 1px solid ${({ active }) => (active ? "#cccccc" : "#d5ddf0")};
  cursor: ${({ active }) => (active ? "default" : "pointer")};

  ${theme.mixins.onEvent} {
    color: ${({ active }) => (active ? "inherit" : `${theme.colors.pink}`)};
    box-shadow: ${({ active }) =>
      active ? "unset" : `${theme.shadows.shadow}`};
    filter: ${({ active }) => (active ? "unset" : "contrast(200%)")};
  }

  ${theme.screens.mdScreen} {
    width: 100%;
    margin: unset;
  }
`;

const Blog = ({ pageContext, data }) => {
  const blogPage = pageContext.pageContent;
  const blogPosts = data.allWpBlogPost.edges;
  return (
    <Layout>
      <Seo
        title={
          pageContext.seo.title ? pageContext.seo.title : pageContext.title
        }
        description={pageContext.seo?.description}
      />
      <SubpageHeader
        title="Blog"
        image={require("assets/images/news-img-bg.png").default}
      />
      <Newsletter />

      <section className="section-news">
        <div className="container">
          <div className="section-news__buttons">
            <Link to="/news/" className="section-news__button">
              <Button>News</Button>
            </Link>
            <Link to="/blog/" className="section-news__button">
              <Button active="true">Blog</Button>
            </Link>
          </div>
          <div className="row section-news__row">
            {blogPosts?.map((edges, index) => {
              const item = edges.node;
              return (
                <div
                  className="col-lg-4 col-md-6 section-news__tile"
                  key={index}
                >
                  <NewsTile
                    link={item.uri}
                    image={item.featuredImage?.node.sourceUrl}
                    date={item.date}
                    title={item.title}
                    desc={item.blogPosts.shortDescription}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query blogPagePosts {
    allWpBlogPost {
      edges {
        node {
          blogPosts {
            shortDescription
          }
          date(formatString: "D MMMM YYYY")
          featuredImage {
            node {
              sourceUrl
            }
          }
          slug
          seo {
            metaDesc
            title
          }
          title
          uri
          language {
            name
            locale
            code
            slug
          }
        }
      }
    }
  }
`;

export default Blog;
